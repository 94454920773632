<template>
  <div class="polls pageInit">
    <h1 class="pageTitle textColor">Polls</h1>
    <div class="filterContainer">
      <!--<el-select v-model="select" placeholder="">-->
      <!--  <el-option-->
      <!--      v-for="item in options"-->
      <!--      :key="item.value"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value"-->
      <!--  >-->
      <!--  </el-option>-->
      <!--</el-select>-->
      <!--<div class="searchContiner">-->
      <!--  <searchBox style="margin-right: 20px" ></searchBox>-->
      <!--  <button class="el-button el-button&#45;&#45;default x-btn">GO</button>-->
      <!--</div>-->
    </div>
    <div class="notificationTabs">
      <router-link to="/notification" class="x-btn">NOTIFICATIONS</router-link>
      <!--<router-link to="/polls" class="x-btn">POLLS</router-link>-->
      <router-link to="/survey" class="x-btn">SURVEY</router-link>
    </div>
    <div class="lists" v-show="!showDetail">
      <div class="list" v-for="(item,index) in lists" :key="'list'+index">
        <div class="listContainer" @click="clickPoll(item)">{{ item.question }}</div>
      </div>
    </div>
    <!--poll detail-->
    <div class="pollsDetail" v-show="showDetail">
      <div class="title">
        <h1>Poll Result</h1>
        <button class="x-btn" @click="clickBack">Back</button>
      </div>
      <div class="mainContent">
        <div class="titleContainer">
          <p class="title">{{ listDetail.pollInfo }}</p>
          <p class="titleDown">{{ listDetail.question }}</p>
        </div>
        <div class="answerContainer" v-show="showAnswer">
          <div class="answers">
            <el-radio-group v-model="submitAnswer">
              <el-radio v-for="item in listDetail.answers" v-model="item._id" :label="item._id">{{ item.answer }}
              </el-radio>
            </el-radio-group>
          </div>
          <button class="x-btn" @click="submit">Submit</button>
        </div>
        <el-row class="echartsContainer" v-show="showEcharts">
          <el-col :xs="24" :md="12" class="leftContainer">
            <div class="question" v-for="(item,index) in listDetail.answers">
              <P class="dot" :style="{'background':color[index]}"></P>
              <p>{{ item.answer }}</p>
            </div>
          </el-col>
          <el-col :xs="24" :md="12" class="rightContainer">
            <div id="myChart123" :style="{width:'400px',height: '400px',margin:'auto'}"></div>
          </el-col>
        </el-row>
      </div>
    </div>


    <!--<div class="lists">-->
    <!--  <div class="list" v-for="(item, index) in lists" :key="'list' + index" @click="goNotification(item)">-->
    <!--    <div class="listContainer">-->
    <!--      &lt;!&ndash;<p class="docIcon">&ndash;&gt;-->
    <!--      &lt;!&ndash;  <img v-if="item.img" :src="item.img" alt=""/>&ndash;&gt;-->
    <!--      &lt;!&ndash;  <i v-else class="iconfont icon-pdf" style="font-size: 30px;color:gray"></i>&ndash;&gt;-->
    <!--      &lt;!&ndash;</p>&ndash;&gt;-->
    <!--      <el-row :gutter="24" style="width: calc(100% + 30px);">-->
    <!--        <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">-->
    <!--          <div class="grid-content">-->
    <!--            <p class="docName">-->
    <!--              <el-row :gutter="24" style="width: calc(100% + 30px);">-->
    <!--                <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">-->
    <!--                  <el-row class="grid-content" style="    display: flex;-->
    <!--                  align-items: center;">-->
    <!--                    <el-row class="dot">-->
    <!--                      <span class="read" v-if="item.isRead==true"></span>-->
    <!--                      <span class="unread" v-if="item.isRead==false"></span>-->
    <!--                    </el-row>-->
    <!--                    -->
    <!--                    <el-row class="subject">-->
    <!--                      <span class="title">{{ item.subject }}</span>-->
    <!--                      <br/>-->
    <!--                      <span class="sentDateTime">{{item.sentDateTime}}</span>-->
    <!--                    </el-row>-->
    <!--                  </el-row>-->
    <!--                </el-col>-->
    <!--                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14" style="display: flex; align-items: center;">-->
    <!--                  <el-row class="message">-->
    <!--                    <span>{{item.message}}</span>-->
    <!--                  </el-row>-->
    <!--                </el-col>-->
    <!--              </el-row>    -->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" style="display: flex; align-items: center;justify-content: center;">-->
    <!--          <div class="grid-content" style="">-->
    <!--            <p class="viewBtn">-->
    <!--              <button class="x-btn" @click="del(item.id)">Dismiss</button>-->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--     -->
    <!--      -->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->

  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  toRaw, watch,
} from "vue";
import searchBox from "@/components/tools/search.vue";
import axios from "@/api/http";
import {useStore} from 'vuex';
import {ElMessage, ElMessageBox} from "element-plus";
import {ElLoading} from 'element-plus';
import notification from "@/store/notification";
import {useRouter, useRoute, onBeforeRouteUpdate} from 'vue-router';
// 引入echarts
import * as echarts from 'echarts'

export default defineComponent({
  name: "Polls",
  components: {
    searchBox,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    onBeforeRouteUpdate((to) => {
      console.log("====Polls To!!!!!!!!!! =====", to)
      data.showAnswer = false;
      data.showEcharts = false;
      if (to.query.id) {
        getDataDetail(to.query.id);
      } else {
        data.showDetail = false;
        getData();
      }
    });
    const data = reactive({
      color:['#fc9d9a','#f9cdad','#c8c8a9','#83af9b','#8a977b','#e58308','#dc5712','#a0bf7c','#e0a09e','#fbda8d','#14446a'],
      showEcharts: false,//展示Echarts
      showAnswer: false,//展示Echarts
      showDetail: false,//展示poll详情页
      select: '',
      options: [
        {label: "All Tags", value: 1},
      ],
      lists: [
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "Virtual Vendor show overview",
        //   id: 1,
        // },
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "MC Event Tutoral",
        //   id: 1,
        // },
        // {img: require("../../assets/imgs/icon.jpg"), name: "Lorem", id: 1},
      ],
      listDetail: [],
      submitAnswer: "",
    });
    watch(() => data.showDetail, (newValue, oldVlaue) => {
      console.log("====newValue =====", newValue)
    });
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.img = item.img;
        obj.name = item.subject;
        obj.id = item.id;
        obj.link = item.link;
        arr.push(obj);
      })
      return arr
    };
    const getData = () => {
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/polls',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad'
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // data.lists = formatData(res.data.data);
        console.log("====res =====", res)
        data.lists = res.data.polls;
        // let isChange = store.state.notification.notificationChange;
        // store.commit("setNotificationChange",!isChange);
        loading.close();
      }).catch(err => {
        console.log(err);
      })
    }
    const goNotification = (i) => {
      // console.log(i);
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'put',
        url: '/virtual/isReadNotification',
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id',
          id: i.id
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // console.log(res)
        if (res.data.data == 'success') {
          loading.close();
        }
      }).catch(err => {
        console.log(err);
      })
    }
    const del = (id) => {
      const loading = ElLoading.service({
        lock: true,
      })
      // console.log(id);
      axios.request({
        method: 'put',
        url: '/virtual/dissmissNotification',
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id',
          id: id
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        if (res.data.data == 'success') {
          loading.close();
        }
      }).catch(err => {
        console.log(err);
      })
    }
    const clickPoll = (poll) => {
      poll = toRaw(poll);
      router.push({
        name: 'Polls',
        query: {id: poll.id},
      });
    }
    const clickBack = () => {
      router.push({
        name: 'Polls',
      })
    }
    const isDetail = () => {
      let currentRoute = route;
      let id = currentRoute.query.id;
      console.log("==== id=====", id)
      if (id) {
        data.showDetail = true;
      } else {
        data.showDetail = false;
      }
    }
    isDetail();
    const submit = () => {
      console.log("==== Answer=====", data.submitAnswer)
      if (data.submitAnswer) {
        const loading = ElLoading.service({
          lock: true,
        })
        let email = localStorage.getItem("elqEmail");
        let pollId = route.query.id;
        axios.request({
          method: 'POST',
          url: '/virtual/pollSubmit',
          data: {
            meetingId: "mg9lr5rzd62to2hcidef11id",
            id: pollId,
            answerId: data.submitAnswer,
            email
          }
        }).then(res => {
          loading.close();
          console.log('res:', res);
          getDataDetail(pollId);
        }).catch(err => {
          loading.close();
          console.log(err);
        })
      } else {
        ElMessageBox.alert('Please Select', {
          confirmButtonText: 'OK',
        })
      }
    }
    const initEcharts = (echartsData) => {
      let myChart = echarts.init(document.getElementById("myChart123"));
      console.log("====Echart Data!!!!!!!! =====", echartsData)
      let arr = [];
      echartsData.map(item => {
        let obj = {};
        obj.name = item.answer;
        obj.value = item.number;
        arr.push(obj);
      })
      console.log("==== arr=====", arr)

      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   top: '0%',
        //   left: 'center'
        // },
        series: [
          {
            // top: '15%',
            top: '0%',
            name: 'Poll Result',
            type: 'pie',
            radius: ['50%', '95%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: arr,
            color:data.color
            // data: [
            //   {value: 1048, name: 'Search Engine'},
            //   {value: 735, name: 'Direct'},
            //   {value: 580, name: 'Email'},
            //   {value: 484, name: 'Union Ads'},
            //   {value: 300, name: 'Video Ads'}
            // ]
          }
        ]
      });
      // window.onresize = function () { // 自适应大小
      //   myChart.resize();
      // };
    }
    const getDataDetail = (pollId) => {
      data.listDetail = [];
      data.showDetail = true;
      let email = localStorage.getItem("elqEmail");
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/pollSubmit',
        data: {
          meetingId: "mg9lr5rzd62to2hcidef11id",
          pollId: pollId || route.query.id,
          email
        }
      }).then(res => {
        loading.close();
        console.log('res:', res);
        data.listDetail = res.data;
        data.showDetail = true;
        if (res.data.result == 'submit') {
          data.showAnswer = true;
          data.showEcharts = false;
        } else {
          data.showAnswer = false;
          data.showEcharts = true;
          initEcharts(res.data.answers);
        }
        console.log("==== listDetail=====", data.listDetail)
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    onBeforeMount(() => {
    });
    onMounted(() => { // 需要获取到element,所以是onMounted的Hook
      if (route.query.id) {
        getDataDetail();
      } else {
        getData();
      }
    });
    return {
      ...toRefs(data),
      route,
      router,
      store,
      getData,
      goNotification,
      del,
      clickPoll,
      clickBack,
      isDetail,
      submit
    };
  },
});
</script>
<style scoped lang='scss'>
.polls {
  .filterContainer {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    justify-content: flex-end;
    /* margin: 20px 0 10px 0; */

    .searchContiner {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  .notificationTabs {
    text-align: center;
    margin: 0 0 30px auto;

    a {
      margin: 0 10px;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
    }
  }

  .lists {
    padding: 0px 0 20px 0;
    border-top: 1px solid #fff;

    .list:nth-child(odd) {
      //background: #f2f3f5;
      background: #e8f5f9;
    }

    .list:nth-child(even) {
      background: #fff;
    }

    .list {
      cursor: pointer;

      .listContainer {
        display: flex;
        align-items: center;
        padding: 15px;
        min-height: 40px;
        font-size: 16px;

        .docIcon {
          width: 200px;
          text-align: center;
        }

        .docName {
          flex: 1;
          font-size: 16px;
          /* display: flex;
          align-items: center; */
          div {
            /* display:inline-block; */
          }

          .subject {
            padding: 0 20px;
          }

          .read {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #B2BECA;
          }

          .unread {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #97D700;
          }

          .title {

          }

          .sentDateTime {
            /* padding: 0 10px; */
          }
        }

        .viewBtn {
          width: auto;

          button {
            background-color: #2dccd3;
            min-width: 120px;
            //font-size:1rem;
            border: 0;
            border-radius: 15px;
            height: 30px;
            font-family: OpenSans-Semibold;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pollsDetail {
    .title {
      display: flex;
      justify-content: space-between;

      button {
        margin-right: 30px;
      }
    }

    .mainContent {
      .titleContainer {
        margin-bottom: 20px;

        .title {
          font-size: 14px;
        }

        .titleDown {
          font-size: 20px;
        }
      }

      .answerContainer {
        display: flex;
        flex-flow: column;
        align-items: center;

        .answers {
          .answer {
            font-size: 16px;
            display: flex;
            flex-flow: column;
          }
        }
      }

      .echartsContainer {
        .leftContainer {
          margin-bottom: 50px;

          .question {
            display: flex;
            font-size: 16px;
            align-items: center;

            .dot {
              width: 10px;
              height: 10px;
              border-radius: 25px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.polls {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px auto;
  }
}
</style>
